@import "../../../assets/styles/colors";

.horizontal-divider {
    position: relative;
    font-weight: 700;
}

.divider {
    background: $divider-gray;
    height: 1px;
}
