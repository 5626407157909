footer {
    width: 100%;
    background-color: #e8e8e8;
}

.terms-and-conditions {
    color: #485465;
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 17px;

    a {
        color: #485465;
        font-family: "Open Sans";
        font-size: 12px;
        line-height: 17px;

        &,
        &:visited,
        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}
