// Text
$text-blue:#225a78;
$text-gray: rgb(72, 84, 101);
$text-white: rgb(255, 255, 255);


// Background
$bg-gray: #F8F8F8;



// Gradient
$gradient-blue: #225A78;

// Dividors
$divider-gray: #d0d0d0;