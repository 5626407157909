$primary-color: #3992b3;
$inactive-color: #b3b4b6;

.pandologic-input {
    width: 100%;
    position: relative;

    .icon svg {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: $inactive-color;
    }
    
    &.filled {
        .icon svg {
            color: $primary-color;
        }
    }

    &.with-icon {
        .MuiInputLabel-outlined {
            transform: translate(36px, 14px) scale(1);
        }
        .MuiInputLabel-outlined.Mui-focused,
        .MuiInputLabel-outlined.MuiFormLabel-filled {
            transform: translate(14px, -6px) scale(0.75);
        }

        input {
            padding-left: 36px;
        }
    }

    input {
        height: 3px !important;
    }

    .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
    }

    .MuiInputBase-root {
        input {
            color: #485465;
        }

        &.Mui-focused {
            color: #485465;
        }
    }

    // Label color + when active
    .MuiFormLabel-root {
        color: $inactive-color;

        &.Mui-focused {
            color: $primary-color;
        }
    }
    // Border color + when active state
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #c2c3c4;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
    }

    // hover color of border
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: lighten($primary-color, 0.8);
    }
    
    &.disabled .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: $inactive-color;
    }

    // filled
    .MuiFormLabel-root.MuiFormLabel-filled {
        color: $primary-color;

        & + .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color;
        }
    }
}
