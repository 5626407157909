.auto-optimize-toggle {
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.10);
  display: inline-block;
  padding: 9px 2px 9px 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

}

.auto-optimize-toggle [name="auto-optimize"] {
  display: none;
}

.auto-optimize-toggle input[type="checkbox"]+label {

  padding: 7px 20px 7px 20px;

  cursor: pointer;
  user-select: none;
  color: #212121;
}

.auto-optimize-toggle input[type="checkbox"]:checked+label {
  background-color: white;
  color: #212121;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}