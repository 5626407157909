@import "./colors";
@import "./pandologic";

h1 {
    font-family: "Open Sans";
}

// Text
.text {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.text-main {
    font-size: 32px;
}

.text-sub {
    font-size: 20px;
}

.text-white {
    color: $text-white;
}

.text-blue {
    color: $text-blue;
}

.text-gray {
    color: $text-gray;
}

.text-numeric {
    font-family: "Comfortaa", sans-serif;
    font-weight: 300;
    font-size: 14px !important;
}

// Other
.cursor-pointer {
    cursor: pointer;
}

img {
    object-fit: contain;
}

// Pandologic material UI overrides
.MuiButton-outlined {
    border-color: #225a78 !important;
    color: #225a78 !important;
}

// Scrollbar
// ::-webkit-scrollbar-track
// {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   border-radius: 10px;
// //   background-color: $green-100;
// }

// ::-webkit-scrollbar
// {
//   width: 12px;
//   background-color: #F5F5F5;
// }

// ::-webkit-scrollbar-thumb
// {
//   border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//   background-image: $gradient-blue;
// }
