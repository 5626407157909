.text-info {
    font-size: 16px;
}

.help-icon {
    &,
    svg {
        height: 16px !important;
        width: 16px !important;
        margin-left: 4px;
        margin-top: 4px;
        color: #999B9D  !important;
        cursor: help;
    }
}


.info-box:not(:last-child) {
    margin-right: 24px;
}


.infobox__header {
    font-size: 16px !important;

    @media (min-width: 1200px) and (max-width: 1549px) {
        font-size: 12px !important;
    }

    @media (min-width: 1550px) and (max-width: 1649px) {
        font-size: 13px !important;
    }

    @media (min-width: 1650px) and (max-width: 1850px) {
        font-size: 14px !important;
    }

}

.infobox__content {
    font-size: 24px !important;

    @media (min-width: 1200px) and (max-width: 1549px) {
        font-size: 16px !important;
    }

    @media (min-width: 1550px) and (max-width: 1649px) {
        font-size: 18px !important;
    }

    @media (min-width: 1650px) and (max-width: 1850px) {
        font-size: 20px !important;
    }

}