@import "../../../../assets/styles/colors";
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
    .ag-root-wrapper {
        border-radius: 5px !important;
    }

    @include ag-theme-alpine(());

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b3b4b6;
    }
}

.ag-header {
    background-image: $gradient-blue;
}
.ag-header-row {
    background-color: #225a78 !important;
}

.ag-header-container {
    min-width: 100% !important;
}

.ag-header-row.ag-header-row-column {
    min-width: 100% !important;
    border-bottom: 3px solid #233f52 !important;
    background-color: #fff !important;
    .ag-header-cell {
        color: #485465 !important;
    }
}

.ag-header-group-cell {
    border-right: 1px solid #dddddd !important;
}

.ag-icon {
    color: #485465 !important;
}

.ag-header-row {
    color: $text-white !important;
}

.ag-header-icon {
    color: $text-white !important;
}

.ag-paging-row-summary-panel-number {
    font-weight: normal !important;
}

.ag-body-viewport {
    overflow: scroll !important;
}

.ag-header-group-cell-label {
    justify-content: center !important;
}

.ag-input-wrapper::after {
    color: #225a78 !important;
}
.ag-tab-selected {
    border-bottom-color: #225a78 !important;
}

.ag-link-column {
    color: #3992b3 !important;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.ag-right-border-column {
    border-right: 1px solid #dddddd !important;

    & .ag-header-cell-resize {
        &::after {
            background-color: transparent;
        }
    }
}

.ag-left-border-column {
    border-left: 1px solid #dddddd !important;

    & .ag-header-cell-resize {
        &::after {
            background-color: transparent !important;
        }
    }
}