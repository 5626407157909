@import "../../../assets/styles/colors";

#header {
    background-color: $gradient-blue;
    height: 80px;
}

.header-title {
    font-size: 24px;
    color: $text-white;
    font-weight: 300;
}

.header-icon {
    & > h4 {
        transition: all 150ms ease-in-out;
    }
    &:hover > h4 {
        transform: scale(1.07);
    }
}

// .pandologic-logo {
//     transition: all 250ms ease-in-out;

//     &:hover {
//         transform: scale(1.03) skew(3deg, 3deg);
//     }
// }
