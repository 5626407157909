.mapboxgl-popup-content {
    padding: 0 !important;
}

.mapboxgl-popup-close-button {
    color: #fff !important;
}

.jobs-map {

}

.popup-section {
    padding: 10px;

    &.body {
        .body-title {
            font-size: 12px;
            font-weight: bold !important;
            font-family: Arial, Helvetica, sans-serif !important;
        }

        .body-content {
            font-family: Arial, Helvetica, sans-serif !important;
            font-weight: normal !important;
        }
    }
}